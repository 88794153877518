<template>
  <div>
    <div
      v-if="loading"
      class="w-full h-full fixed block top-0 left-0 bg-white opacity-75 z-50"
    >
      <span
        class="text-green-500 opacity-75 top-1/2 my-0 mx-auto block relative w-0 h-0"
        style="top: 50%"
      >
        <font-awesome-icon :icon="['fas', 'spinner']" size="5x" spin />
      </span>
    </div>
    <div
      v-if="done"
      class="w-full h-full fixed block top-0 left-0 bg-white opacity-75 z-50"
    >
      <span
        class="text-green-500 opacity-75 top-1/2 my-0 mx-auto block relative w-0 h-0"
        style="top: 50%"
      >
        <font-awesome-icon :icon="['fas', 'check']" size="5x" />
      </span>
    </div>

    <form action="#" method="POST" ref="form" v-if="!loading && !done">
      <div v-if="showError">
        <div
          class="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative"
          role="alert"
        >
          <strong class="font-bold">Unable to submit the report:</strong>
          <ul>
            <li v-for="(message, index) in errorMessages" :key="index">
              {{ message }}
            </li>
          </ul>
        </div>
      </div>

      <div class="shadow sm:rounded-md sm:overflow-hidden">
        <div class="px-4 py-5 bg-white sm:p-6">
          <!--<h1 class="text-l font-semibold">Vendor Submission Form</h1>-->
          <p class="mb-4 mt-2 text-m">
            Please fill in the form below. Indicate as much details as you can
            in the description pane.<br />Please attach files, screenshots and
            photos. Thank you for reporting a Brand Protection issue!
          </p>
          <hr />
        </div>
        <div class="px-4 py-5 bg-white space-y-6 sm:p-6">
          <!--<div class="pb-3">
            <label for="subject" class="block text-sm font-medium text-gray-700">
              Subject<span class="text-red-500 pl-1">*</span>
            </label>
            <div class="mt-1 flex rounded-md shadow-sm">
              <input v-model="toSendProperty.subject" type="text" name="subject" id="subject" class="flex-1 block w-full rounded-sm sm:text-sm border-gray-300 focus:outline-none focus:text-gray-600 p-2" placeholder="[vendor name] - - [Customs or Law enforcement] detain (xxx) [product type] in [city] on [date mm.dd.yyyy] - [your reference id]" required>
            </div>
          </div> -->

          <div class="pb-10">
            <label
              for="description"
              class="block text-sm font-medium text-gray-700"
            >
              Description<span class="text-red-500 pl-1">*</span>
            </label>
            <div class="mt-1">
              <vue-simplemde
                v-model="toSendProperty.description"
                ref="markdownEditor"
                class="pb-5"
                id="description"
                name="description"
              />
            </div>
          </div>
          <div class="pb-3 grid md:grid-cols-3 sm:grid-cols-1 gap-20">
            <div class="col-span-1">
              <label
                :for="'cf_' + properties.submatter.id"
                class="block text-sm font-medium text-gray-700"
              >
                {{ properties.submatter.name }}
              </label>
              <div class="mt-1 flex rounded-md shadow-sm">
                <select
                @change="setVisibility()"
                  :name="'cf_' + properties.submatter.id"
                  :id="'cf_' + properties.submatter.id"
                  class="focus:outline-none focus:text-gray-600 p-2 flex-1 block w-full rounded-sm sm:text-sm border-gray-300"
                  v-model="toSendProperty['cf_' + properties.submatter.id]"
                >
                  <option
                    v-for="submatterOption in properties.submatter
                      .possible_values"
                    :value="submatterOption.value"
                  >
                    {{ submatterOption.label }}
                  </option>
                </select>
              </div>
            </div>
          </div>
          <div class="pb-3 grid md:grid-cols-3 sm:grid-cols-1 gap-20">
            
            <div class="col-span-1">
              <label
                for="start_date"
                class="block text-sm font-medium text-gray-700"
              >
                Action date<span class="text-red-500 pl-1">*</span>
              </label>
              <div class="mt-1 flex rounded-md shadow-sm">
                <input
                  required
                  type="date"
                  name="start_date"
                  id="start_date"
                  class="flex-1 block w-full rounded-sm sm:text-sm border-gray-300 focus:outline-none focus:text-gray-600 p-2"
                  v-model="toSendProperty['start_date']"
                />
              </div>
            </div>
            <div class="col-span-1" v-if="showDeadline">
              <label
                for="start_date"
                class="block text-sm font-medium text-gray-700"
              >
                Deadline date
              </label>
              <div class="mt-1 flex rounded-md shadow-sm">
                <input
                  
                  type="date"
                  name="deadline_date"
                  id="deadline_date"
                  class="flex-1 block w-full rounded-sm sm:text-sm border-gray-300 focus:outline-none focus:text-gray-600 p-2"
                  v-model="toSendProperty['cf_581']"
                />
              </div>
            </div>
            
          </div>

          <div class="pb-3 grid md:grid-cols-4 sm:grid-cols-1 gap-20">
            <div class="col-span-1">
              <label
                :for="'cf_' + properties.region.id"
                class="block text-sm font-medium text-gray-700"
              >
                {{ properties.region.name
                }}<span class="text-red-500 pl-1">*</span>
              </label>
              <div class="mt-1 flex rounded-md shadow-sm">
                <select
                  required
                  v-model="toSendProperty['cf_' + properties.region.id]"
                  :name="'cf_' + properties.region.id"
                  :id="'cf_' + properties.region.id"
                  class="focus:outline-none focus:text-gray-600 p-2 flex-1 block w-full rounded-sm sm:text-sm border-gray-300"
                >
                  <option
                    v-for="regionOption in properties.region.possible_values"
                    :value="regionOption.value"
                  >
                    {{ regionOption.label }}
                  </option>
                </select>
              </div>
            </div>
            <div class="col-span-1">
              <label
                :for="'cf_' + properties.country.id"
                class="block text-sm font-medium text-gray-700"
              >
                {{ properties.country.name
                }}<span class="text-red-500 pl-1">*</span>
              </label>
              <div class="mt-1 flex rounded-md shadow-sm">
                <select
                  v-model="toSendProperty['cf_' + properties.country.id]"
                  :name="'cf_' + properties.country.id"
                  :id="'cf_' + properties.country.id"
                  class="focus:outline-none focus:text-gray-600 p-2 flex-1 block w-full rounded-sm sm:text-sm border-gray-300"
                  required
                >
                  <option
                    v-for="countryOption in countries[
                      toSendProperty['cf_' + properties.region.id]
                    ]"
                    :value="countryOption"
                  >
                    {{ countryOption }}
                  </option>
                </select>
              </div>
            </div>
            <div class="col-span-1">
              <label
                :for="'cf_' + properties.citystate.id"
                class="block text-sm font-medium text-gray-700"
              >
                {{ properties.citystate.name
                }}<span class="text-red-500 pl-1">*</span>
              </label>
              <div class="mt-1 flex rounded-md shadow-sm">
                <input
                  required
                  v-model="toSendProperty['cf_' + properties.citystate.id]"
                  type="text"
                  :name="'cf_' + properties.citystate.id"
                  :id="'cf_' + properties.citystate.id"
                  class="focus:outline-none focus:text-gray-600 p-2 flex-1 block w-full rounded-sm sm:text-sm border-gray-300"
                  placeholder="City/State/Province"
                />
              </div>
            </div>
            <div class="col-span-1">
              <label
                :for="'cf_' + properties.mapgps.id"
                class="block text-sm font-medium text-gray-700"
              >
                {{ properties.mapgps.name
                }}<span class="text-red-500 pl-1">*</span>
              </label>
              <div class="mt-1 flex rounded-md shadow-sm">
                <input
                  required
                  @input="validateInput"
                  v-model="toSendProperty['cf_' + properties.mapgps.id]"
                  type="text"
                  :name="'cf_' + properties.mapgps.id"
                  :id="'cf_' + properties.mapgps.id"
                  class="focus:outline-none focus:text-gray-600 p-2 flex-1 block w-full rounded-sm sm:text-sm border-gray-300"
                  placeholder="Map/Gps"
                />
               
                
              </div>
               <!-- Display the error message if it's set -->
              <p v-if="errorMessage" class="text-xs text-red mt-2">{{ errorMessage }}</p>
            </div>
          </div>
          <div class="pb-3 grid md:grid-cols-4 sm:grid-cols-1 gap-20">
            <div
                class="col-span-1"
                v-if="showImportExport"
              >
                <label
                  :for="'cf_' + properties.exporterCity.id"
                  class="block text-sm font-medium text-gray-700"
                >
                  {{ properties.exporterCity.name }}
                </label>
                <div class="mt-1 flex rounded-md shadow-sm">
                  <input
                    v-model="toSendProperty['cf_' + properties.exporterCity.id]"
                    type="text"
                    :name="'cf_' + properties.exporterCity.id"
                    :id="'cf_' + properties.exporterCity.id"
                    class="focus:outline-none focus:text-gray-600 p-2 flex-1 block w-full rounded-sm sm:text-sm border-gray-300"
                    placeholder="Exporter City/State"
                  />
                </div>
              </div>
              <div
                class="col-span-1"
                v-if="showImportExport"
              >
                <label
                  :for="'cf_' + properties.exporterCountry.id"
                  class="block text-sm font-medium text-gray-700"
                >
                  {{ properties.exporterCountry.name }}
                </label>
                <div class="mt-1 flex rounded-md shadow-sm">
                  <input
                    v-model="toSendProperty['cf_' + properties.exporterCountry.id]"
                    type="text"
                    :name="'cf_' + properties.exporterCountry.id"
                    :id="'cf_' + properties.exporterCountry.id"
                    class="focus:outline-none focus:text-gray-600 p-2 flex-1 block w-full rounded-sm sm:text-sm border-gray-300"
                    placeholder="Exporter Country"
                  />
                </div>
              </div>

              
              <div
                class="col-span-1"
                v-if="showImportExport"
              >
                <label
                  :for="'cf_' + properties.importerCity.id"
                  class="block text-sm font-medium text-gray-700"
                >
                  {{ properties.importerCity.name }}
                </label>
                <div class="mt-1 flex rounded-md shadow-sm">
                  <input
                    v-model="toSendProperty['cf_' + properties.importerCity.id]"
                    type="text"
                    :name="'cf_' + properties.importerCity.id"
                    :id="'cf_' + properties.importerCity.id"
                    class="focus:outline-none focus:text-gray-600 p-2 flex-1 block w-full rounded-sm sm:text-sm border-gray-300"
                    placeholder="Importer City/State"
                  />
                </div>
              </div>
              <div
                class="col-span-1"
                v-if="showImportExport"
              >
                <label
                  :for="'cf_' + properties.importerCountry.id"
                  class="block text-sm font-medium text-gray-700"
                >
                  {{ properties.importerCountry.name }}
                </label>
                <div class="mt-1 flex rounded-md shadow-sm">
                  <input
                    v-model="toSendProperty['cf_' + properties.importerCountry.id]"
                    type="text"
                    :name="'cf_' + properties.importerCountry.id"
                    :id="'cf_' + properties.importerCountry.id"
                    class="focus:outline-none focus:text-gray-600 p-2 flex-1 block w-full rounded-sm sm:text-sm border-gray-300"
                    placeholder="Importer Country"
                  />
                </div>
              </div>
          </div>
         <!-- <div class="pb-3 grid md:grid-cols-3 sm:grid-cols-1 gap-20">
            <div class="col-span-1">
              <label
                :for="'cf_' + properties.repnamesurname.id"
                class="block text-sm font-medium text-gray-700"
              >
                {{ properties.repnamesurname.name
                }}<span class="text-red-500 pl-1">*</span>
              </label>
              <div class="mt-1 flex rounded-md shadow-sm">
                <input
                  required
                  v-model="toSendProperty['cf_' + properties.repnamesurname.id]"
                  type="text"
                  :name="'cf_' + properties.repnamesurname.id"
                  :id="'cf_' + properties.repnamesurname.id"
                  class="focus:outline-none focus:text-gray-600 p-2 flex-1 block w-full rounded-sm sm:text-sm border-gray-300"
                  placeholder="Name Surname"
                />
              </div>
            </div>
            <div class="col-span-1">
              <label
                :for="'cf_' + properties.repemail.id"
                class="block text-sm font-medium text-gray-700"
              >
                {{ properties.repemail.name
                }}<span class="text-red-500 pl-1">*</span>
              </label>
              <div class="mt-1 flex rounded-md shadow-sm">
                <input
                  required
                  v-model="toSendProperty['cf_' + properties.repemail.id]"
                  type="email"
                  :name="'cf_' + properties.repemail.id"
                  :id="'cf_' + properties.repemail.id"
                  class="focus:outline-none focus:text-gray-600 p-2 flex-1 block w-full rounded-sm sm:text-sm border-gray-300"
                  placeholder="Email"
                />
              </div>
            </div>
            <div class="col-span-1">
              <label
                :for="'cf_' + properties.repphone.id"
                class="block text-sm font-medium text-gray-700"
              >
                {{ properties.repphone.name }}
              </label>
              <div class="mt-1 flex rounded-md shadow-sm">
                <input
                  v-model="toSendProperty['cf_' + properties.repphone.id]"
                  type="text"
                  :name="'cf_' + properties.repphone.id"
                  :id="'cf_' + properties.repphone.id"
                  class="focus:outline-none focus:text-gray-600 p-2 flex-1 block w-full rounded-sm sm:text-sm border-gray-300"
                  placeholder="Phone number"
                />
              </div>
            </div>
            <div class="col-span-1">
              <label :for="'cf_'+properties.contactme.id" class="block text-sm font-medium text-gray-700">
                {{properties.contactme.name}}<span class="text-red-500 pl-1">*</span>
              </label>
              <div class="mt-1 flex" :name="'cf_'+properties.contactme.id" :id="'cf_'+properties.contactme.id">
                <label class="inline-flex items-center pl-1" v-for="contactmeOption in properties.contactme.possible_values">
                  <input v-model="toSendProperty['cf_'+properties.contactme.id]" type="radio" class="form-radio" :name="'cf_'+properties.contactme.id" :value="contactmeOption.value">
                  <span class="ml-2">{{contactmeOption.label}}</span>
                </label>
              </div>
            </div>
          </div>-->
          <div class="pb-3 grid md:grid-cols-3 sm:grid-cols-1 gap-20">
            <!--<div class="col-span-1">
              <label :for="'cf_'+properties.vendorName.id" class="block text-sm font-medium text-gray-700">
                Vendor<span class="text-red-500 pl-1">*</span>
              </label>
              <div class="mt-1 flex rounded-md shadow-sm">
                <input v-model="toSendProperty['cf_'+properties.vendorName.id]" type="text" :name="'cf_'+properties.vendorName.id" :id="'cf_'+properties.vendorName.id" class="focus:outline-none focus:text-gray-600 p-2 flex-1 block w-full rounded-sm sm:text-sm border-gray-300" placeholder="Vendor" @keypress="onlyNumber">
              </div>
            </div>-->

            <!--<div class="col-span-1">
              <label :for="'cf_'+properties.vendorNotes.id" class="block text-sm font-medium text-gray-700">
                Vendor Name
              </label>
              <div class="mt-1 flex rounded-md shadow-sm">
                <input v-model="toSendProperty['cf_'+properties.vendorNotes.id]" type="text" :name="'cf_'+properties.vendorName.id" :id="'cf_'+properties.vendorNotes.id" class="focus:outline-none focus:text-gray-600 p-2 flex-1 block w-full rounded-sm sm:text-sm border-gray-300" placeholder="Vendor Name">
              </div>
            </div>-->
            
          </div>

          <div class="pb-3 grid md:grid-cols-3 sm:grid-cols-1 gap-20">
            <div class="col-span-1">
              <label
                :for="'cf_' + properties.vendorReporterCode.id"
                class="block text-sm font-medium text-gray-700"
              >
                Reporter Code (<span @click="openSearch('reporter',properties.vendorReporterCode.id)">ask UA</span>)<span class="text-red-500 pl-1">*</span>
              </label>
              <div class="mt-1 flex rounded-md shadow-sm relative">
                <input
                  required
                  v-model="
                    toSendProperty['cf_' + properties.vendorReporterCode.id]
                  "
                  type="text"
                  :name="'cf_' + properties.vendorReporterCode.id"
                  :id="'cf_' + properties.vendorReporterCode.id"
                  class="focus:outline-none focus:text-gray-600 p-2 flex-1 block w-full rounded-sm sm:text-sm border-gray-300"
                  placeholder="Reporter Code"
                />
                <button  @click="openSearch('reporter',properties.vendorReporterCode.id)" class="absolute inset-y-0 right-0 flex items-center px-4 bg-gray-100 text-gray-400 focus:outline-none focus:text-gray-600 p-2 rounded-sm sm:text-sm border-gray-30">
                  <svg class="h-5 w-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M14.795 13.408l5.204 5.204a1 1 0 01-1.414 1.414l-5.204-5.204a7.5 7.5 0 111.414-1.414zM8.5 14A5.5 5.5 0 103 8.5 5.506 5.506 0 008.5 14z" />
                  </svg>
                </button>
              </div>
            </div>

            <div class="col-span-1">
              <label
                :for="'cf_' + properties.repnamesurname.id"
                class="block text-sm font-medium text-gray-700"
              >
                {{ properties.repnamesurname.name
                }}<span class="text-red-500 pl-1">*</span>
              </label>
              <div class="mt-1 flex rounded-md shadow-sm">
                <input
                  required
                  v-model="toSendProperty['cf_' + properties.repnamesurname.id]"
                  type="text"
                  :name="'cf_' + properties.repnamesurname.id"
                  :id="'cf_' + properties.repnamesurname.id"
                  class="focus:outline-none focus:text-gray-600 p-2 flex-1 block w-full rounded-sm sm:text-sm border-gray-300"
                  placeholder="Name Surname"
                />
              </div>
            </div>
          
          </div>
          <div class="pb-3 grid md:grid-cols-3 sm:grid-cols-1 gap-20">
            <div class="col-span-1">
              <label
                :for="'cf_' + properties.vendorName.id"
                class="block text-sm font-medium text-gray-700"
              >
              Law Firm Code (<span @click="openSearch('vendor',properties.vendorName.id)">ask UA</span>)<span class="text-red-500 pl-1">*</span>
              </label>
              <div class="mt-1 flex rounded-md shadow-sm relative">
                <input
                  required
                  v-model="toSendProperty['cf_' + properties.vendorName.id]"
                  type="text"
                  :name="'cf_' + properties.vendorName.id"
                  :id="'cf_' + properties.vendorName.id"
                  class="focus:outline-none focus:text-gray-600 p-2 flex-1 block w-full rounded-sm sm:text-sm border-gray-300"
                  placeholder="Vendor Code"
                  @keypress="onlyNumber"
                />
                <button  @click="openSearch('vendor',properties.vendorName.id)" class="absolute inset-y-0 right-0 flex items-center px-4 bg-gray-100 text-gray-400 focus:outline-none focus:text-gray-600 p-2 rounded-sm sm:text-sm border-gray-30">
                  <svg class="h-5 w-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M14.795 13.408l5.204 5.204a1 1 0 01-1.414 1.414l-5.204-5.204a7.5 7.5 0 111.414-1.414zM8.5 14A5.5 5.5 0 103 8.5 5.506 5.506 0 008.5 14z" />
                  </svg>
                </button>
              </div>
            </div>

            <div class="col-span-1">
              <label
                :for="'cf_' + properties.vendorid.id"
                class="block text-sm font-medium text-gray-700"
              >
                Vendor Internal Ref.
              </label>
              <div class="mt-1 flex rounded-md shadow-sm">
                <input
                  
                  v-model="toSendProperty['cf_' + properties.vendorid.id]"
                  type="text"
                  :name="'cf_' + properties.vendorid.id"
                  :id="'cf_' + properties.vendorid.id"
                  class="focus:outline-none focus:text-gray-600 p-2 flex-1 block w-full rounded-sm sm:text-sm border-gray-300"
                  placeholder="Vendor Ref. Nr."
                />
              </div>
            </div>
          </div>

          <div class="pb-3 grid md:grid-cols-3 sm:grid-cols-1 gap-20">
            <div class="col-span-1" v-if="showLawEnforcementCode">
              <label
                :for="'cf_' + properties.vendorLawEnforcement.id"
                class="block text-sm font-medium text-gray-700"
              >
                Law Enforcement Code (<span @click="openSearch('law_enforcement',properties.vendorLawEnforcement.id)">ask UA</span>)<span class="text-red-500 pl-1">*</span>
              </label>
              <div class="relative mt-1 flex rounded-md shadow-sm">
                <input
                  required
                  v-model="
                    toSendProperty['cf_' + properties.vendorLawEnforcement.id]
                  "
                  type="text"
                  :name="'cf_' + properties.vendorLawEnforcement.id"
                  :id="'cf_' + properties.vendorLawEnforcement.id"
                  class="focus:outline-none focus:text-gray-600 p-2 flex-1 block w-full rounded-sm sm:text-sm border-gray-300"
                  placeholder="Law Enforcement Code"
                  @keypress="onlyNumber"
                />
                <button  @click="openSearch('law_enforcement',properties.vendorLawEnforcement.id)" class="absolute inset-y-0 right-0 flex items-center px-4 bg-gray-100 text-gray-400 focus:outline-none focus:text-gray-600 p-2 rounded-sm sm:text-sm border-gray-30">
                  <svg class="h-5 w-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M14.795 13.408l5.204 5.204a1 1 0 01-1.414 1.414l-5.204-5.204a7.5 7.5 0 111.414-1.414zM8.5 14A5.5 5.5 0 103 8.5 5.506 5.506 0 008.5 14z" />
                  </svg>
                </button>
              </div>
            </div>
            <div class="col-span-1" v-if="showLawEnforcementRef">
              <label
                :for="'cf_' + properties.vendorid.id"
                class="block text-sm font-medium text-gray-700"
              >
                Law Enforcement Ref. Nr.
              </label>
              <div class="mt-1 flex rounded-md shadow-sm">
                <input
                  
                  v-model="toSendProperty['cf_' + properties.lawenforcementref.id]"
                  type="text"
                  :name="'cf_' + properties.lawenforcementref.id"
                  :id="'cf_' + properties.lawenforcementref.id"
                  class="focus:outline-none focus:text-gray-600 p-2 flex-1 block w-full rounded-sm sm:text-sm border-gray-300"
                  placeholder="Law Enforcement Ref. Nr."
                />
              </div>
            </div>
            
          </div>

          <div class="pb-3 grid md:grid-cols-3 sm:grid-cols-1 gap-20">
            <div class="col-span-1" v-if="showPlaceOfInterest">
              <label
                :for="'cf_' + properties.placeofinterest.id"
                class="block text-sm font-medium text-gray-700"
              >
                {{ properties.placeofinterest.name
                }}<span class="text-red-500 pl-1">*</span>
              </label>
              <div
                class="mt-1 flex flex-col rounded-md shadow-sm"
                :name="'cf_' + properties.placeofinterest.id"
                :id="'cf_' + properties.placeofinterest.id"
              >
              <!--
                <label class="inline-flex items-center pt-4 pl-1">
                  <input
                  :disabled="toSendProperty['cf_' + properties.submatter.id] == 'Customs Action'"
                    v-model="
                      toSendProperty['cf_' + properties.placeofinterest.id]
                    "
                    :value="null"
                    type="radio"
                    class="form-radio"
                    :name="'cf_' + properties.placeofinterest.id"
                    checked
                    required
                  />
                  <span class="ml-2">(none)</span>
                </label>
              -->
                <label
                  class="inline-flex items-center pt-4 pl-1"
                  v-for="placeofinterestOption in properties.placeofinterest
                    .possible_values"
                    
                >
               
                  <input
                    @change="setVisibility()"
                    :disabled="toSendProperty['cf_' + properties.submatter.id] == 'Customs Action' || (placeofinterestOption.value=='27' && toSendProperty['cf_' + properties.submatter.id] == 'Law Enforcement Raid')"
                    v-model="
                      toSendProperty['cf_' + properties.placeofinterest.id]
                    "
                    type="radio"
                    class="form-radio"
                    :name="'cf_' + properties.placeofinterest.id"
                    :value="placeofinterestOption.value"
                    required
                  />
                  <span class="ml-2">{{ placeofinterestOption.label }}</span>
                </label>
              </div>
            </div>
            <div class="col-span-1">
              <div class="col-span-1 rounded-md shadow-sm">
                <label
                  :for="'cf_' + properties.offline.id"
                  class="block text-sm font-medium text-gray-700"
                >
                  {{ properties.offline.name }}
                </label>
                <div
                  class="mt-1 flex"
                  :name="'cf_' + properties.offline.id"
                  :id="'cf_' + properties.offline.id"
                >
                  <label class="inline-flex items-center pl-1">
                    <input
                      type="radio"
                      class="form-radio"
                      :name="'cf_' + properties.offline.id"
                      @click="
                        toSendProperty['cf_' + properties.offline.id] = ''
                      "
                    />
                    <span class="ml-2">(none)</span>
                  </label>

                  <label
                    class="inline-flex items-center pl-10"
                    v-for="offlineOption in properties.offline.possible_values"
                  >
                    <input
                      v-model="toSendProperty['cf_' + properties.offline.id]"
                      type="radio"
                      class="form-radio"
                      :name="'cf_' + properties.offline.id"
                      :value="offlineOption.value == '1' ? '1' : '0'"
                    />
                    <span class="ml-2">{{ offlineOption.label }}</span>
                  </label>
                </div>
              </div>
              <!--<div class="col-span-1 mt-4 rounded-md shadow-sm">
                <label :for="'cf_'+properties.online.id" class="block text-sm font-medium text-gray-700">
                  {{ properties.online.name }}
                </label>
                <div class="mt-1 flex" :name="'cf_'+properties.online.id" :id="'cf_'+properties.online.id">
                  <label class="inline-flex items-center pl-1">
                    <input type="radio" class="form-radio" :name="'cf_'+properties.online.id" checked @click="toSendProperty['cf_'+properties.online.id]=''">
                    <span class="ml-2">(none)</span>
                  </label>
                  <label class="inline-flex items-center pl-10" v-for="onlineOption in properties.online.possible_values">
                    <input v-model="toSendProperty['cf_'+properties.online.id]" type="radio" class="form-radio" :name="'cf_'+properties.online.id" :value="onlineOption.value">
                    <span class="ml-2">{{onlineOption.label}}</span>
                  </label>
                </div>
              </div>-->
             
              <div
                class="col-span-1 mt-8"
                v-if="showCustomsType"
              >
                <label
                  :for="'cf_' + properties.customstype.id"
                  class="block text-sm font-medium text-gray-700"
                >
                  {{ properties.customstype.name
                  }}<span class="text-red-500 pl-1">*</span>
                </label>
                <div class="mt-1 flex rounded-md shadow-sm">
                  <select
                    v-model="toSendProperty['cf_' + properties.customstype.id]"
                    :name="'cf_' + properties.customstype.id"
                    :id="'cf_' + properties.customstype.id"
                    class="focus:outline-none focus:text-gray-600 p-2 flex-1 block w-full rounded-sm sm:text-sm border-gray-300"
                    required
                  >
                    <option
                      v-for="customstypeOption in properties.customstype
                        .possible_values"
                      :value="customstypeOption.value"
                    >
                      {{ customstypeOption.label }}
                    </option>
                  </select>
                </div>
              </div>
            </div>
            <div class="col-span-1">
              <div
                class="col-span-1 mt-4"
                v-if="toSendProperty['cf_' + properties.online.id] == 1"
              >
                <label
                  :for="'cf_' + properties.website.id"
                  class="block text-sm font-medium text-gray-700"
                >
                  {{ properties.website.name }}
                </label>
                <div class="mt-1 flex rounded-md shadow-sm">
                  <input
                    v-model="toSendProperty['cf_' + properties.website.id]"
                    type="text"
                    :name="'cf_' + properties.website.id"
                    :id="'cf_' + properties.website.id"
                    class="focus:outline-none focus:text-gray-600 p-2 flex-1 block w-full rounded-sm sm:text-sm border-gray-300"
                    placeholder="Website link"
                  />
                </div>
              </div>
              <div
                class="col-span-1 mt-4"
                v-if="showStoreName"
              >
                <label
                  :for="'cf_' + properties.storelink.id"
                  class="block text-sm font-medium text-gray-700"
                >
                  {{ properties.storelink.name }}
                </label>
                <div class="mt-1 flex rounded-md shadow-sm">
                  <input
                    v-model="toSendProperty['cf_' + properties.storelink.id]"
                    type="text"
                    :name="'cf_' + properties.storelink.id"
                    :id="'cf_' + properties.storelink.id"
                    class="focus:outline-none focus:text-gray-600 p-2 flex-1 block w-full rounded-sm sm:text-sm border-gray-300"
                    placeholder="Store link"
                  />
                </div>
              </div>
            </div>
          </div>

          <div class="pb-3 grid md:grid-cols-3 sm:grid-cols-1 gap-20">
            <div class="col-span-1">
              <label
                :for="'cf_' + properties.producttype1.id"
                class="block text-sm font-medium text-gray-700"
              >
                {{ properties.producttype1.name
                }}<span class="text-red-500 pl-1">*</span>
              </label>
              <div class="mt-1 flex rounded-md shadow-sm">
                <select
                  required
                  v-model="toSendProperty['cf_' + properties.producttype1.id]"
                  :name="'cf_' + properties.producttype1.id"
                  :id="'cf_' + properties.producttype1.id"
                  class="focus:outline-none focus:text-gray-600 p-2 flex-1 block w-full rounded-sm sm:text-sm border-gray-300"
                >
                  <option
                    v-for="producttypeOption in properties.producttype1
                      .possible_values"
                    :value="producttypeOption.value"
                  >
                    {{ producttypeOption.label }}
                  </option>
                </select>
              </div>
            </div>
            <div class="col-span-1">
              <label
                :for="'cf_' + properties.nrunits1.id"
                class="block text-sm font-medium text-gray-700"
              >
                {{ properties.nrunits1.name
                }}<span class="text-red-500 pl-1">*</span>
              </label>
              <div class="mt-1 flex rounded-md shadow-sm">
                <input
                  required
                  v-model="toSendProperty['cf_' + properties.nrunits1.id]"
                  type="text"
                  :name="'cf_' + properties.nrunits1.id"
                  :id="'cf_' + properties.nrunits1.id"
                  class="focus:outline-none focus:text-gray-600 p-2 flex-1 block w-full rounded-sm sm:text-sm border-gray-300"
                  placeholder="Nr. Units"
                  @keypress="onlyNumber"
                />
              </div>
            </div>
          </div>

          <div class="pb-3 grid md:grid-cols-3 sm:grid-cols-1 gap-20">
            <div class="col-span-1">
              <label
                :for="'cf_' + properties.producttype2.id"
                class="block text-sm font-medium text-gray-700"
              >
                {{ properties.producttype2.name }}
              </label>
              <div class="mt-1 flex rounded-md shadow-sm">
                <select
                  v-model="toSendProperty['cf_' + properties.producttype2.id]"
                  :name="'cf_' + properties.producttype2.id"
                  :id="'cf_' + properties.producttype2.id"
                  class="focus:outline-none focus:text-gray-600 p-2 flex-1 block w-full rounded-sm sm:text-sm border-gray-300"
                >
                  <option
                    v-for="producttypeOption in properties.producttype2
                      .possible_values"
                    :value="producttypeOption.value"
                  >
                    {{ producttypeOption.label }}
                  </option>
                </select>
              </div>
            </div>
            <div class="col-span-1">
              <label
                :for="'cf_' + properties.nrunits2.id"
                class="block text-sm font-medium text-gray-700"
              >
                {{ properties.nrunits2.name }}
              </label>
              <div class="mt-1 flex rounded-md shadow-sm">
                <input
                  v-model="toSendProperty['cf_' + properties.nrunits2.id]"
                  type="text"
                  :name="'cf_' + properties.nrunits2.id"
                  :id="'cf_' + properties.nrunits2.id"
                  class="focus:outline-none focus:text-gray-600 p-2 flex-1 block w-full rounded-sm sm:text-sm border-gray-300"
                  placeholder="Nr. Units"
                  @keypress="onlyNumber"
                />
              </div>
            </div>
          </div>

          <div class="pb-3 grid md:grid-cols-3 sm:grid-cols-1 gap-20">
            <div class="col-span-1">
              <label
                :for="'cf_' + properties.producttype3.id"
                class="block text-sm font-medium text-gray-700"
              >
                {{ properties.producttype3.name }}
              </label>
              <div class="mt-1 flex rounded-md shadow-sm">
                <select
                  v-model="toSendProperty['cf_' + properties.producttype3.id]"
                  :name="'cf_' + properties.producttype3.id"
                  :id="'cf_' + properties.producttype3.id"
                  class="focus:outline-none focus:text-gray-600 p-2 flex-1 block w-full rounded-sm sm:text-sm border-gray-300"
                >
                  <option
                    v-for="producttypeOption in properties.producttype3
                      .possible_values"
                    :value="producttypeOption.value"
                  >
                    {{ producttypeOption.label }}
                  </option>
                </select>
              </div>
            </div>
            <div class="col-span-1">
              <label
                :for="'cf_' + properties.nrunits3.id"
                class="block text-sm font-medium text-gray-700"
              >
                {{ properties.nrunits3.name }}
              </label>
              <div class="mt-1 flex rounded-md shadow-sm">
                <input
                  v-model="toSendProperty['cf_' + properties.nrunits3.id]"
                  type="text"
                  :name="'cf_' + properties.nrunits3.id"
                  :id="'cf_' + properties.nrunits3.id"
                  class="focus:outline-none focus:text-gray-600 p-2 flex-1 block w-full rounded-sm sm:text-sm border-gray-300"
                  placeholder="Nr. Units"
                  @keypress="onlyNumber"
                />
              </div>
            </div>



            <div class="col-span-1">
              <label
                :for="'cf_' + properties.proposedFee.id"
                class="block text-sm font-medium text-gray-700"
              >
                {{ properties.proposedFee.name
                }}<span class="text-red-500 pl-1">*</span>
              </label>
              <div class="mt-1 flex rounded-md shadow-sm">
                <input
                  required
                  @paste.prevent
                  v-model="toSendProperty['cf_' + properties.proposedFee.id]"
                  type="text"
                  :name="'cf_' + properties.proposedFee.id"
                  :id="'cf_' + properties.proposedFee.id"
                  class="focus:outline-none focus:text-gray-600 p-2 flex-1 block w-full rounded-sm sm:text-sm border-gray-300"
                  placeholder="Proposed Fee (USD)"
                  @keypress="onlyNumber"
                />
              </div>
            </div>

            <div class="col-span-1">
                <label
                  :for="'cf_' + properties.photos_attached.id"
                  class="block text-sm font-medium text-gray-700"
                >
                  {{ properties.photos_attached.name }}<span class="text-red-500 pl-1">*</span>
                </label>
                <div
                  class="mt-1 flex"
                  :name="'cf_' + properties.photos_attached.id"
                  :id="'cf_' + properties.photos_attached.id"
                >
                  
                  <label
                    class="inline-flex items-center pr-10"
                    v-for="photos_attachedOption in properties.photos_attached.possible_values"
                  >
                    <input
                      v-model="toSendProperty['cf_' + properties.photos_attached.id]"
                      type="radio"
                      class="form-radio"
                      :name="'cf_' + properties.photos_attached.id"
                      :value="photos_attachedOption.value == '1' ? '1' : '0'"
                      required="required"
                    />
                    <span class="ml-2">{{ photos_attachedOption.label }}</span>
                  </label>
                </div>
              </div>
          </div>

          <div v-cloak @drop.prevent="addFile" @dragover.prevent>
            <label class="block text-sm font-medium text-gray-700">
              Files
            </label>
            <div
              class="mt-2 flex justify-center px-6 pt-5 pb-6 border-2 border-gray-300 border-dashed rounded-md"
            >
              <div class="space-y-1 text-center">
                <svg
                  class="mx-auto h-12 w-12 text-gray-400"
                  stroke="currentColor"
                  fill="none"
                  viewBox="0 0 48 48"
                  aria-hidden="true"
                >
                  <path
                    d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>

                <div class="flex text-sm text-gray-600">
                  <label
                    for="file"
                    class="relative cursor-pointer bg-white rounded-md font-medium text-indigo-600 hover:text-indigo-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500"
                  >
                    <span>Upload a file</span>
                    <input
                      ref="fileInput"
                      id="file"
                      name="file"
                      type="file"
                      class="sr-only"
                      v-on:change="handleFileUpload()"
                    />
                  </label>
                  <p class="pl-1">or drag and drop</p>
                </div>
                <p class="text-xs text-gray-500">
                  PNG, JPG, PDF, XLS, DOC up to 8MB
                </p>
              </div>
            </div>
            <ul class="mt-4">
              <li v-for="file in files" class="text-sm">
                {{ file.name }} ({{ file.size | kb }} kb)
                <button @click="removeFile(file)" title="Remove">x</button>
              </li>
            </ul>
          </div>
          <div class="px-4 py-3 bg-gray-50 text-right sm:px-6">
            <button
              type="button"
              class="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-gray-500 hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
              @click="submitForm()"
            >
              Submit the case
            </button>
          </div>
        </div>
      </div>
    </form>

    <!-- Dialogo -->

    <div
      v-if="isOpen"
      class="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full z-50"
    >
      <div
        class="relative top-20 mx-auto p-5 border w-3/5 shadow-lg rounded-md bg-white"
      >
        <!-- Icona 'X' per chiudere -->
        <div class="absolute top-0 right-0 pt-2 pr-2">
          <button
            @click="isOpen = false"
            class="text-gray-400 hover:text-gray-500"
          >
            <svg
              class="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          </button>
        </div>
        <div class="mt-3 text-center">
          <h3 class="text-lg leading-6 font-medium text-gray-900">
            Insert a text string and click the Lens icon to search it
          </h3>
          <div class="mt-2">
            <VendorSearch @selectionChanged="updateVendorID" :filter="searchFilter"/>
          </div>
          
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { axiosInstance as client } from "@/util/axios";
import VueSimplemde from "vue-simplemde";
import "simplemde/dist/simplemde.min.css";
import VendorSearch from "@/components/ToolComponent/VendorSearch";

export default {
  name: "Vendors",
  components: {
    VueSimplemde,
    VendorSearch,
  },
  data() {
    return {
      coordPattern: /^([-+]?)([\d]{1,2})(((\.)(\d+)(,)))(\s*)(([-+]?)([\d]{1,3})((\.)(\d+))?)$/,
      isOpen: false,
      showError: false,
      errorMessages: null,
      properties: null,
      done: false,
      files: [],
      toSendProperty: [],
      loading: true,
      startDate: null,
      deadlineDate: null,
      searchFilter: null,
      element: null,
      errorMessage: null,
      showStoreName: false,
      showPlaceOfInterest: false,
      showCustomsType: false,
      showDeadline: false,
      showImportExport: false,
      showLawEnforcementCode: false,
      showLawEnforcementRef: false,
      vendorName: null,
      countries: {
        AMERICAS: [
          "All Countries",
          "Anguilla",
          "Antigua and Barbuda",
          "Argentina",
          "Aruba",
          "Bahamas",
          "Barbados",
          "Belize",
          "Bermuda",
          "Bolivia",
          "Bonaire, Saint Eustatius and Saba",
          "Bouvet Island",
          "Brazil",
          "Canada",
          "Cayman Islands",
          "Chile",
          "Colombia",
          "Costa Rica",
          "Cuba",
          "Curaçao",
          "Dominica",
          "Dominican Republic",
          "Ecuador",
          "El Salvador",
          "Falkland Islands (Malvinas)",
          "French Guiana",
          "Grenada",
          "Guadeloupe",
          "Guatemala",
          "Guyana",
          "Haiti",
          "Honduras",
          "Invalid IP",
          "Jamaica",
          "Martinique",
          "Mexico",
          "Montserrat",
          "Netherlands Antilles",
          "Nicaragua",
          "Other",
          "Panama",
          "Paraguay",
          "Peru",
          "Puerto Rico",
          "Saint Barthelemy",
          "Saint Kitts and Nevis",
          "Saint Lucia",
          "Saint Martin",
          "Saint Pierre and Miquelon",
          "Saint Vincent and the Grenadines",
          "Sint Maarten",
          "South Georgia and the South Sandwich Islands",
          "Suriname",
          "Trinidad and Tobago",
          "Turks and Caicos Islands",
          "United States",
          "Uruguay",
          "Venezuela",
          "Virgin Islands, British",
          "Virgin Islands, U.S.",
        ],
        APAC: [
          "All Countries",
          "Aland Islands",
          "American Samoa",
          "Armenia",
          "Australia",
          "Bangladesh",
          "Bhutan",
          "Brunei",
          "Cambodia",
          "China",
          "Cook Islands",
          "Fiji",
          "French Polynesia",
          "Guam",
          "Hong Kong",
          "India",
          "Indonesia",
          "Japan",
          "Kiribati",
          "Laos",
          "Macao",
          "Malaysia",
          "Maldives",
          "Marshall Islands",
          "Micronesia",
          "Mongolia",
          "Myanmar",
          "Nauru",
          "Nepal",
          "New Caledonia",
          "New Zealand",
          "Niue",
          "North Korea",
          "Northern Mariana Islands",
          "Pakistan",
          "Palau",
          "Papua New Guinea",
          "Philippines",
          "Samoa",
          "Singapore",
          "Solomon Islands",
          "South Korea",
          "Sri Lanka",
          "Taiwan",
          "Thailand",
          "Tokelau",
          "Tonga",
          "Tuvalu",
          "Vanuatu",
          "Vietnam",
          "Wallis And Futuna",
        ],
        EMEA: [
          "All Countries",
          "Afghanistan",
          "Albania",
          "Algeria",
          "Andorra",
          "Angola",
          "Austria",
          "Azerbaijan",
          "Bahrain",
          "Belarus",
          "Belgium",
          "Benin",
          "Bosnia and Herzegovina",
          "Botswana",
          "Bulgaria",
          "Burkina Faso",
          "Burundi",
          "Cameroon",
          "Cape Verde",
          "Central African Republic",
          "Chad",
          "Comoros",
          "Congo",
          "Congo RDC",
          "Côte D'Ivoire",
          "Croatia",
          "Cyprus",
          "Czech Republic",
          "Denmark",
          "Djibouti",
          "Egypt",
          "Equatorial Guinea",
          "England",
          "Eritrea",
          "Estonia",
          "Ethiopia",
          "Europe",
          "Faroe Islands",
          "Finland",
          "France",
          "Gabon",
          "Gambia",
          "Georgia",
          "Germany",
          "Ghana",
          "Gibraltar",
          "Greece",
          "Greenland",
          "Guernsey",
          "Guinea",
          "Guinea-Bissau",
          "Holy See (Vatican City State)",
          "Hungary",
          "Iceland",
          "Iran",
          "Iraq",
          "Ireland",
          "Isle of Man",
          "Israel",
          "Italy",
          "Jersey",
          "Jordan",
          "Kazakhstan",
          "Kyrgyzstan",
          "Kenya",
          "Kosovo",
          "Kuwait",
          "Latvia",
          "Lebanon",
          "Lesotho",
          "Liberia",
          "Libya",
          "Liechtenstein",
          "Lithuania",
          "Luxembourg",
          "Macedonia",
          "Madagascar",
          "Malawi",
          "Mali",
          "Malta",
          "Mauritania",
          "Mauritius",
          "Mayotte",
          "Moldova Rep.",
          "Monaco",
          "Montenegro",
          "Morocco",
          "Mozambique",
          "Namibia",
          "Netherlands",
          "Niger",
          "Nigeria",
          "Norway",
          "OAPI",
          "Oman",
          "Palestinian Territory",
          "Poland",
          "Portugal",
          "Qatar",
          "Romania",
          "Russian Federation",
          "Rwanda",
          "Saint Helena",
          "San Marino",
          "Sao Tome and Principe",
          "Saudi Arabia",
          "Scotland",
          "Senegal",
          "Serbia",
          "Seychelles",
          "Sierra Leone",
          "Slovakia",
          "Slovenia",
          "Somalia",
          "South Africa",
          "South Sudan",
          "Spain",
          "Sudan",
          "Svalbard and Jan Mayen",
          "Swaziland",
          "Sweden",
          "Syrian Arab Republic",
          "Switzerland",
          "Tadjikistan",
          "Tanzania, United Republic of",
          "Togo",
          "Tunisia",
          "Turkey",
          "Turkmenistan",
          "Uganda",
          "Ukraine",
          "United Arab Emirates",
          "United Kingdom",
          "Uzbekistan",
          "Wales",
          "Western Sahara",
          "WIPO",
          "Yemen",
          "Zambia",
          "Zimbabwe",
        ],
        INTERNET: [
          "All Countries",
          "Market Place App & Social Media",
          "Domain",
          "Website",
          "Other",
        ],
        GLOBAL: ["Other"],
      },
    };
  },
  computed: {
    uploadDisabled() {
      return this.files.length === 0;
    },
  },
  beforeMount() {
    let self = this;
    self.getProperty();
  },
  mounted() {
    this.toSendProperty["cf_2"] = 'Customs Action'
    this.toSendProperty["cf_38"] = 27;
    this.setVisibility()
  },
  methods: {
    
    validateInput() {
      if (this.coordPattern.test(this.toSendProperty['cf_' + this.properties.mapgps.id])) {
        // If the input matches the pattern, clear any error message
        this.errorMessage = '';
        return true
      } else {
        // If the input doesn't match, set an error message
        this.errorMessage = 'Please enter valid coordinates.';
        return false
      }
    },
    openSearch(searchType,element){
      this.searchFilter = searchType;
      this.element = element;
      this.isOpen = true;
    },
    onlyNumber($event) {
      //console.log($event.keyCode); //keyCodes value
      let keyCode = $event.keyCode ? $event.keyCode : $event.which;
      if ((keyCode < 48 || keyCode > 57) && keyCode !== 46) {
        // 46 is dot
        $event.preventDefault();
      }
    },
    //vmodel con i cf che sono i custom field
    getProperty: function () {
      this.loading = true;
      client
        .get("/fields", {})
        .then((response) => {
          //console.log(response.data.fields)
          if (response.data.status === "success") {
            this.loadProperty(response.data.fields);
          }
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    loadProperty: function (data) {
      let ProdValues = ['Mens-Accessories','Mens-Apparel','Mens-Footwear','Womens-Accessories','Womens-Apparel','Womens-Footwear','Youth-Accessories','Youth-Apparel','Youth-Footwear'];

      let temp_properties = {};
      for (let i = 0; i < data.length; i++) {
        switch (data[i].id) {
          case 2:
            console.log(data[i])
            temp_properties.submatter = data[i];
            let values = ['Customs Action', 'Law Enforcement Raid', 'Law Enforcement Training', 'Investigation - Other'];
            temp_properties.submatter.possible_values = temp_properties.submatter.possible_values.filter(item => (values.includes(item.value)));
            this.$set(
              this.toSendProperty,
              "cf_" + temp_properties.submatter.id,
              temp_properties.submatter.default_value
            );
            break;
          case 39:
            temp_properties.region = data[i];
            this.$set(
              this.toSendProperty,
              "cf_" + temp_properties.region.id,
              temp_properties.region.default_value
            );
            break;
          case 32:
            temp_properties.startDate = data[i];
            this.$set(
              this.toSendProperty,
              "cf_" + temp_properties.startDate.id,
              temp_properties.startDate.default_value
            );
            break;
          case 581:
            temp_properties.deadlineDate = data[i];
            this.$set(
              this.toSendProperty,
              "cf_" + temp_properties.deadlineDate.id,
              temp_properties.deadlineDate.default_value
            );
            break;
          case 4:
            temp_properties.country = data[i];
            this.$set(
              this.toSendProperty,
              "cf_" + temp_properties.country.id,
              temp_properties.country.default_value
            );
            break;
          case 15:
            temp_properties.citystate = data[i];
            this.$set(
              this.toSendProperty,
              "cf_" + temp_properties.citystate.id,
              temp_properties.citystate.default_value
            );
            break;
          case 40:
            temp_properties.mapgps = data[i];
            this.$set(
              this.toSendProperty,
              "cf_" + temp_properties.mapgps.id,
              temp_properties.mapgps.default_value
            );
            break;
          case 38:
            temp_properties.placeofinterest = data[i];
            this.$set(
              this.toSendProperty,
              "cf_" + temp_properties.placeofinterest.id,
              '27'
            );
            break;
          case 108:
            temp_properties.offline = data[i];
            temp_properties.offline.default_value = "1";
            this.$set(
              this.toSendProperty,
              "cf_" + temp_properties.offline.id,
              temp_properties.offline.default_value
            );
            break;
          case 582:
            temp_properties.photos_attached = data[i];
            temp_properties.photos_attached.default_value = "";
            this.$set(
              this.toSendProperty,
              "cf_" + temp_properties.photos_attached.id,
              temp_properties.photos_attached.default_value
            );
            break;
          case 17:
            temp_properties.storelink = data[i];
            this.$set(
              this.toSendProperty,
              "cf_" + temp_properties.storelink.id,
              temp_properties.storelink.default_value
            );
            break;
          case 26:
            temp_properties.online = data[i];
            this.$set(
              this.toSendProperty,
              "cf_" + temp_properties.online.id,
              temp_properties.online.default_value
            );
            break;
          case 124:
            temp_properties.website = data[i];
            this.$set(
              this.toSendProperty,
              "cf_" + temp_properties.website.id,
              temp_properties.website.default_value
            );
            break;
          case 7:
            temp_properties.producttype1 = data[i];
            temp_properties.producttype1.possible_values = temp_properties.producttype1.possible_values.filter(item => (!ProdValues.includes(item.value)));

            this.$set(
              this.toSendProperty,
              "cf_" + temp_properties.producttype1.id,
              temp_properties.producttype1.default_value
            );
            break;
          case 184:
            temp_properties.producttype2 = data[i];
            temp_properties.producttype2.possible_values = temp_properties.producttype2.possible_values.filter(item => (!ProdValues.includes(item.value)));

            this.$set(
              this.toSendProperty,
              "cf_" + temp_properties.producttype2.id,
              temp_properties.producttype2.default_value
            );
            break;
          case 350:
            temp_properties.producttype3 = data[i];
            temp_properties.producttype3.possible_values = temp_properties.producttype3.possible_values.filter(item => (!ProdValues.includes(item.value)));

            this.$set(
              this.toSendProperty,
              "cf_" + temp_properties.producttype3.id,
              temp_properties.producttype3.default_value
            );
            break;
          case 18:
            temp_properties.vendorid = data[i];
            this.$set(
              this.toSendProperty,
              "cf_" + temp_properties.vendorid.id,
              temp_properties.vendorid.default_value
            );
            break;
          case 120:
            temp_properties.repnamesurname = data[i];
            this.$set(
              this.toSendProperty,
              "cf_" + temp_properties.repnamesurname.id,
              temp_properties.repnamesurname.default_value
            );
            break;
          case 122:
            temp_properties.repemail = data[i];
            this.$set(
              this.toSendProperty,
              "cf_" + temp_properties.repemail.id,
              temp_properties.repemail.default_value
            );
            break;
          case 123:
            temp_properties.repphone = data[i];
            this.$set(
              this.toSendProperty,
              "cf_" + temp_properties.repphone.id,
              temp_properties.repphone.default_value
            );
            break;
          case 125:
            temp_properties.contactme = data[i];
            this.$set(
              this.toSendProperty,
              "cf_" + temp_properties.contactme.id,
              temp_properties.contactme.default_value
            );
            break;
          /*case 18:
            temp_properties.vendor = data[i];
            this.$set(this.toSendProperty, 'cf_'+temp_properties.vendor.id, temp_properties.vendor.default_value)
            break;*/
          case 103:
            temp_properties.vendorName = data[i];
            this.$set(
              this.toSendProperty,
              "cf_" + temp_properties.vendorName.id,
              temp_properties.vendorName.default_value
            );
            break;
          case 31:
            temp_properties.vendorNotes = data[i];
            this.$set(
              this.toSendProperty,
              "cf_" + temp_properties.vendorNotes.id,
              temp_properties.vendorNotes.default_value
            );
            break;
          case 239:
            temp_properties.proposedFee = data[i];
            this.$set(
              this.toSendProperty,
              "cf_" + temp_properties.proposedFee.id,
              temp_properties.proposedFee.default_value
            );
            break;
          case 21:
            temp_properties.nrunits1 = data[i];
            this.$set(
              this.toSendProperty,
              "cf_" + temp_properties.nrunits1.id,
              temp_properties.nrunits1.default_value
            );
            break;
          case 185:
            temp_properties.nrunits2 = data[i];
            this.$set(
              this.toSendProperty,
              "cf_" + temp_properties.nrunits2.id,
              temp_properties.nrunits2.default_value
            );
            break;
          case 352:
            temp_properties.nrunits3 = data[i];
            this.$set(
              this.toSendProperty,
              "cf_" + temp_properties.nrunits3.id,
              temp_properties.nrunits3.default_value
            );
            break;
          case 190:
            temp_properties.customstype = data[i];
            this.$set(
              this.toSendProperty,
              "cf_" + temp_properties.customstype.id,
              temp_properties.customstype.default_value
            );
            break;
          case 94:
            temp_properties.vendorLawEnforcement = data[i];
            this.$set(
              this.toSendProperty,
              "cf_" + temp_properties.vendorLawEnforcement.id,
              temp_properties.vendorLawEnforcement.default_value
            );
            break;
          case 27:
            temp_properties.lawenforcementref = data[i];
            this.$set(
              this.toSendProperty,
              "cf_" + temp_properties.lawenforcementref.id,
              temp_properties.lawenforcementref.default_value
            );
            break;
          case 76:
            temp_properties.vendorReporterCode = data[i];
            this.$set(
              this.toSendProperty,
              "cf_" + temp_properties.vendorReporterCode.id,
              temp_properties.vendorReporterCode.default_value
            );
            break;
          case 590:
            temp_properties.exporterCity = data[i];
            this.$set(
              this.toSendProperty,
              "cf_" + temp_properties.exporterCity.id,
              temp_properties.exporterCity.default_value
            );
            break;
          case 591:
            temp_properties.exporterCountry = data[i];
            this.$set(
              this.toSendProperty,
              "cf_" + temp_properties.exporterCountry.id,
              temp_properties.exporterCountry.default_value
            );
            break;
            case 592:
            temp_properties.importerCity = data[i];
            this.$set(
              this.toSendProperty,
              "cf_" + temp_properties.importerCity.id,
              temp_properties.importerCity.default_value
            );
            break;
          case 593:
            temp_properties.importerCountry = data[i];
            this.$set(
              this.toSendProperty,
              "cf_" + temp_properties.importerCountry.id,
              temp_properties.importerCountry.default_value
            );
            break;
        }
      }

      this.properties = temp_properties;

      let desc =
        "Please type in case details in this window\n\
\n\
Important & Mandatory:\n\
- Customs / Law enforcement details, including the name and exact address of the authority, a contact name, surname and job title.\n\
- Exact GPS coordinates of location where seizure happened or where the seizing authority is located\n\
- Use the following GPS coordinate: latitude, longitude -> 39.27427689378425, -76.59134485187579\n\
- Action Date is the date the raid/inspection/seizing action was completed";
      this.$set(this.toSendProperty, "description", desc);
      //console.log('prop', this.properties)
    },

    submitForm: function () {
      if (this.$refs.form.checkValidity() && this.validateInput()) {
        this.loading = true;

        

        this.toSendProperty["cf_240"] = this.toSendProperty["cf_239"];

        //[Vendor Name] + “- - “ +[Tipo Azione]*+ “ (“+[Total Nr. Units 1-3]+”) in “ + [City/State/Province]+” on “+[Action Date]+” – “+[Vendor Internal Ref.]
        /*
        • “Customs detain “ IF Place of Interest= “(C)ustoms”
        • “LE raid and detain” IF Place of Interest <> “(C)ustoms”
        */
        let tipoAzione = "LE raid and detain ";

        if (this.toSendProperty["cf_2"] == "Customs Action") {
          tipoAzione = "Customs detain ";
        } else if (this.toSendProperty["cf_2"] == "Law Enforcement Raid") {
          tipoAzione = "LE Raid and detain ";
        } else if (this.toSendProperty["cf_2"] == "Investigation - Other") {
          tipoAzione = "Investigation into ";
        } else if (this.toSendProperty["cf_2"] == "Market/City Survey") {
          tipoAzione = "City/Market Survey in ";
        }

        let place = "";
        if (
          this.toSendProperty["cf_" + this.properties.placeofinterest.id] == "7"
        ) {
          place =
            " " +
            this.toSendProperty["cf_" + this.properties.storelink.id] +
            " Shop - ";
        } else if (
          this.toSendProperty["cf_" + this.properties.placeofinterest.id] == "8"
        ) {
          place =
            " " +
            this.toSendProperty["cf_" + this.properties.storelink.id] +
            " Warehouse - ";
        } else if (
          this.toSendProperty["cf_" + this.properties.placeofinterest.id] ==
          "104"
        ) {
          place =
            " " +
            this.toSendProperty["cf_" + this.properties.storelink.id] +
            " Factory - ";
        }

        let units =
          "(" +
          this.toSendProperty["cf_" + this.properties.nrunits1.id] +
          ")" +
          " " +
          this.toSendProperty["cf_" + this.properties.producttype1.id];

        if (this.toSendProperty["cf_" + this.properties.nrunits2.id] != "") {
          units =
            units +
            " and " +
            "(" +
            this.toSendProperty["cf_" + this.properties.nrunits2.id] +
            ")" +
            " " +
            this.toSendProperty["cf_" + this.properties.producttype2.id];
        }

        if (this.toSendProperty["cf_" + this.properties.nrunits3.id] != "") {
          units =
            units +
            " and " +
            "(" +
            this.toSendProperty["cf_" + this.properties.nrunits3.id] +
            ")" +
            " " +
            this.toSendProperty["cf_" + this.properties.producttype3.id];
        }
        //this.toSendProperty.subject = this.toSendProperty['cf_'+this.properties.vendorName.id] + ' - ' + tipoAzione + units + ' in ' + this.toSendProperty['cf_'+this.properties.citystate.id] + ' on ' + this.toSendProperty['start_date'] + ' - ' + this.toSendProperty['cf_'+this.properties.vendorid.id]

        let desc = null;

        if(this.showDeadline){
          desc = "On ${Today}, ${Assignee} was informed by ${Vendor} that ${LawEnforcement} has detained ${TotalUnits} ${Products} on ${StartDate} in ${City}, ${Country}.\n\nPhotos Attached: ${Photos}\n\nDeadline: ${Deadline}";
        } else {
          desc = "On ${Today}, ${Assignee} was informed by ${Vendor} that ${LawEnforcement} has detained ${TotalUnits} ${Products} on ${StartDate} in ${City}, ${Country}.\n\nPhotos Attached: ${Photos}";
        }
        let completeDescription = "============================================================================\n\nRecap\n\n" + desc + '\n\n============================================================================\n\n' + this.toSendProperty["description"] 


        if (
          this.toSendProperty["cf_2"] == "Customs Action" ||
          this.toSendProperty["cf_2"] == "Law Enforcement Raid"
        ) {
          this.toSendProperty.subject =
            place +
            tipoAzione +
            units +
            " in " +
            this.toSendProperty["cf_" + this.properties.citystate.id] +
            " on " +
            this.toSendProperty["start_date"] +
            " - " +
            this.toSendProperty["cf_" + this.properties.vendorid.id];
        } else if (this.toSendProperty["cf_2"] == "Investigation - Other") {
          this.toSendProperty.subject =
            this.vendorName +
            " --  Investigation into " +
            this.toSendProperty["cf_" + this.properties.citystate.id] +
            " " +
            this.toSendProperty["cf_17"] +
            " on " +
            this.toSendProperty["start_date"] +
            " - " +
            this.toSendProperty["cf_" + this.properties.vendorName.id];
        } else if (this.toSendProperty["cf_2"] == "Law Enforcement Training") {
          this.toSendProperty.subject =
            "LE Customs " +
            this.toSendProperty["cf_" + this.properties.citystate.id] +
            " " +
            this.toSendProperty["cf_" + this.properties.country.id] +
            " - " +
            this.toSendProperty["start_date"];
        } else {
          this.toSendProperty.subject =
            place +
            tipoAzione +
            units +
            " in " +
            this.toSendProperty["cf_" + this.properties.citystate.id] +
            " on " +
            this.toSendProperty["start_date"] +
            " - " +
            this.toSendProperty["cf_" + this.properties.vendorid.id];
        }

        this.toSendProperty.start_date = this.toSendProperty["start_date"];
        let data = this.toSendProperty;
        const formData = new FormData();

        Object.entries(this.toSendProperty).forEach(([key, value]) => {
          formData.append(key, value);
        });

        Object.entries(this.files).forEach(([key, value]) => {
          formData.append("file_" + key, value);
        });
        formData.delete("description");
        formData.append("description", completeDescription);

        var querystring = require("querystring");
        client
          .post("/case", formData)
          .then((response) => {
            console.log(response.data);
            if (response.data.status === "success") {
              this.done = true;
            } else {
              this.showError = true;
              this.errorMessages = response.data.errors.message;
            }
          })
          .catch((error) => {
            console.log(error);
          })
          .finally(() => {
            this.loading = false;
          });
      } else {
        this.$refs.form.reportValidity();
      }
    },
    addFile(e) {
      let droppedFiles = e.dataTransfer.files;
      if (!droppedFiles) return;
      [...droppedFiles].forEach((f) => {
        this.files.push(f);
      });
    },
    removeFile(file) {
      this.files = this.files.filter((f) => {
        return f != file;
      });
    },
    handleFileUpload() {
      this.files.push(this.$refs.fileInput.files[0]);
    },
    updateVendorID(item){
      this.isOpen = false;
      this.toSendProperty['cf_' + this.element] = item.ID
      if(this.element == '103') this.vendorName = item.Company
    },
    setVisibility(){
      console.log(this.toSendProperty["cf_2"])
      switch (this.toSendProperty["cf_2"]) {
          case 'Customs Action':
            this.showPlaceOfInterest = true;
            this.toSendProperty["cf_38"] = 27;
            this.showCustomsType = true;
            this.showDeadline = true;
            this.showStoreName = false;
            this.disabledCustoms = true;
            this.showLawEnforcementCode = true;
            this.showLawEnforcementRef = true;
            this.showImportExport = true;
            break;
          case 'Law Enforcement Raid':
            this.showPlaceOfInterest = true;
            this.toSendProperty["cf_38"] = 7;
            this.showCustomsType = false;
            this.showDeadline = false;
            this.showStoreName = true;
            this.disabledCustoms = true;
            this.showLawEnforcementCode = true;
            this.showLawEnforcementRef = true;
            this.showImportExport = false;
            break;
          case 'Investigation - Other':
            this.showPlaceOfInterest = false;
            this.toSendProperty["cf_38"] = '';
            this.showCustomsType = false;
            this.showDeadline = false;
            this.showLawEnforcementCode = false;
            this.showLawEnforcementRef = false;
            this.showStoreName = true;
            this.showImportExport = false;
            break;
          case 'Law Enforcement Training':
            this.showPlaceOfInterest = false;
            this.toSendProperty["cf_38"] = '';
            this.showCustomsType = false;
            this.showDeadline = false;
            this.showLawEnforcementCode = true;
            this.showLawEnforcementRef = false;
            this.showStoreName = false;
            this.showImportExport = false;
            break;
        }
    }
  },
  watch: {
    toSendProperty: {
      // This will let Vue know to look inside the array
      deep: true,
      // We have to move our method to a handler field
      handler(val) {
        //console.log(this.toSendProperty);
        
        


        if (
          this.toSendProperty["cf_" + this.properties.submatter.id] ==
            "Internet" ||
          this.toSendProperty["cf_" + this.properties.submatter.id] ==
            "Market Place, App & Social Media" ||
          this.toSendProperty["cf_" + this.properties.submatter.id] ==
            "Domains & Websites"
        ) {
          this.$set(this.toSendProperty, "cf_" + this.properties.online.id, 1);
          this.$set(this.toSendProperty, "cf_" + this.properties.offline.id, 0);
        } else {
          this.$set(this.toSendProperty, "cf_" + this.properties.online.id, "");
          this.$set(
            this.toSendProperty,
            "cf_" + this.properties.offline.id,
            "1"
          );
        }
      },
    },
  },
};
</script>

<style scoped>
label.block {
  font-weight: 600;
}
</style>
